<div *ngIf="isVisible">
    <img
        src="/assets/color-logo-no-bg.svg"
        class="animate__animated animate__jackInTheBox"
        height="500"
        style="
            margin-left: auto;
            margin-right: auto;
            padding-top: 10rem;
            display: block;
        "
    />
</div>
