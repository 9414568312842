<div class="service-box">
    <div class="icon">
        <i [class]="'fa fa-' + icon"></i>
    </div>
    <div class="title">
        <h2>{{ title }}</h2>
    </div>
    <div class="description">
        <p>{{ description }}</p>
    </div>
</div>
