<div class="row">
    <div
        class="col-md-6 order-md-2 right-image"
        style="background-color: #a7a3a3 !important"
    >
        <figure class="figure">
            <img
                src="/assets/walking_down_street.jpeg"
                class="img-fluid shadow-2-strong"
                alt=""
                style="border-radius: 8px"
            />
        </figure>
    </div>
    <div
        class="col-md-6 order-md-1 left-image"
        style="background-color: #a7a3a3 !important"
    >
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Welcome to ZeeBee WebDesigns</h5>
                <p class="card-text" style="font-size: large">
                    your premier destination for exceptional web design
                    solutions. <br />
                    With over five years of dedicated experience in the field of
                    web development, we pride ourselves on delivering top-notch
                    designs that transform your online presence. At ZeeBee
                    WebDesigns, we understand the importance of a captivating
                    and user-friendly website in today's digital landscape.
                    <br />
                    Our mission is to craft visually stunning websites that not
                    only engage your audience but also drive tangible results
                    for your business. Whether you're a startup, small business,
                    or established enterprise, we have the expertise to meet
                    your unique needs and elevate your online brand. <br />
                    As the sole proprietor of ZeeBee WebDesigns, I bring a
                    wealth of knowledge and expertise to every project. I have
                    honed my skills over the years, working with a diverse range
                    of clients and industries. This experience has equipped me
                    with a deep understanding of the latest design trends,
                    cutting-edge technologies, and industry best practices.
                    <br />

                    When you choose ZeeBee WebDesigns, you can expect a
                    personalized and collaborative approach. I take the time to
                    listen to your specific requirements, goals, and vision,
                    ensuring that every aspect of your website reflects your
                    brand identity. From concept to completion, I am committed
                    to delivering exceptional designs that surpass your
                    expectations. <br />

                    Whether you need a responsive website, an e-commerce
                    platform, or a stunning portfolio site, ZeeBee WebDesigns
                    has you covered. Using the latest tools and techniques, I
                    create seamless user experiences that are optimized for
                    performance, accessibility, and search engine visibility.
                    <br />

                    Join the ranks of our satisfied clients and experience the
                    power of a professionally crafted website. <br />
                    Contact ZeeBee WebDesigns today to discuss your project and
                    let's embark on a journey towards online success together.
                </p>
            </div>
        </div>
        <hr class="hr hr-blurry" />
        <img
            src="/assets/computer-laptop-table-technology.jpg"
            class="figure-img img-fluid rounded shadow-3 mb-3 left-image"
            alt="..."
        />
    </div>
</div>
