<body>
    <div class="wrapper">
        <div
            class="container py-5 top-image"
            style="background-color: rgb(248, 247, 247); border-radius: 17px"
        >
            <h1 style="text-align: center">Get to Know ZeeBee Web Designs</h1>

            <div
                class="container py-5"
                style="
                    background-color: rgb(248, 247, 247);
                    border-radius: 17px;
                    margin-bottom: 1em;
                "
            >
                <div class="row">
                    <div class="container-fluid">
                        <p>
                            We are a skilled web design agency dedicated to
                            creating high-quality, visually appealing, and
                            user-friendly websites. Our expertise lies in
                            crafting unique online experiences that reflect your
                            brand, values, and objectives. We specialize in
                            creating modern, responsive websites that adapt
                            seamlessly to different devices and screen sizes.
                            Staying up-to-date with the latest design trends, we
                            ensure your website not only looks visually stunning
                            but also meets the expectations of today's
                            tech-savvy users. We start by understanding your
                            business goals, target audience, and desired
                            outcomes. Through open communication and
                            collaboration, we work closely with you to bring
                            your vision to life. Whether you need a new website
                            or want to revamp an existing one, we tailor our
                            services to meet your specific needs.
                        </p>
                    </div>
                </div>

                <div class="container-fluid">
                    <img
                        src="../../../assets/about-page.jpeg"
                        class="img-fluid"
                        alt="..."
                    />
                </div>
            </div>
        </div>
    </div>
</body>
