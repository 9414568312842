<body>
    <app-splash-screen [isVisible]="showSplashScreen"></app-splash-screen>
    <div [hidden]="showSplashScreen">
        <app-header
            [activePage]="activePage"
            [pageTitle]="pageTitle"
        ></app-header>

        <router-outlet
            (activate)="setActivePage($event.componentName)"
        ></router-outlet>
        <app-footer></app-footer>
    </div>
</body>
