<footer class="text-center text-white" style="background-color: #8d8e8e">
    <!-- Grid container -->
    <div class="container pt-4">
        <!-- Section: Social media -->
        <section class="mb-4">
            <!-- Facebook -->
            <a
                mdbRipple
                rippleColor="dark"
                class="btn btn-link btn-floating btn-lg text-dark m-1"
                href="https://www.facebook.com/profile.php?id=100093459780177"
                role="button"
                ><i class="fab fa-facebook-f"></i
            ></a>

            <!-- Google -->
            <a
                mdbRipple
                rippleColor="dark"
                class="btn btn-link btn-floating btn-lg text-dark m-1"
                href="https://g.page/r/CfAawxeMU67pEAI/review"
                role="button"
                ><i class="fab fa-google"></i
            ></a>
        </section>
        <!-- Section: Social media -->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div
        class="text-center text-dark p-3"
        style="background-color: rgba(0, 0, 0, 0.2)"
    >
        Powered By:
        <a href="https://zeebeewebdesigns.com" style="color: rgb(13, 39, 87)"
            >ZeeBee Web Designs</a
        >
    </div>
    <!-- Copyright -->
</footer>
