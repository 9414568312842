import { Component } from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss'],
})
export class ServicesPageComponent {
    componentName = 'ServicesComponent';
}
