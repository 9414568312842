<body style="background-color: rgba(160, 158, 158, 0.6)">
    <div class="container">
        <div class="p-5 text-center bg-light top-image">
            <h1 class="mb-3">Custom Solutions Designed For Your Business</h1>
            <h4 class="mb-3">
                Welcome to our comprehensive service offerings designed to meet
                your digital needs. At our company, we specialize in delivering
                top-notch solutions to help your business thrive in the online
                world. From reliable Cloud Hosting services that ensure optimal
                performance and security to innovative Website Design that
                captures your brand's essence, we've got you covered. Our team
                of experts also provides Website Maintenance services to keep
                your online presence running smoothly, allowing you to focus on
                your core business. Additionally, our Search Engine Optimization
                (SEO) strategies empower your website to rank higher in search
                results, driving more organic traffic and boosting your online
                visibility. Explore our services and let us be your trusted
                partner in achieving digital success.
            </h4>
            <a class="btn btn-primary" href="/contact" role="button"
                >Contact Us To Get Started</a
            >
        </div>
        <div class="container">
            <div class="row bottom-image">
                <div class="col-lg-3 col-md-6">
                    <app-service-box
                        icon="cloud"
                        title="Cloud Hosting"
                        description="Power your business with scalable and flexible solutions for secure data storage and seamless access anytime, anywhere."
                    ></app-service-box>
                </div>
                <div class="col-lg-3 col-md-6">
                    <app-service-box
                        icon="pencil"
                        title="Website Design"
                        description="Elevate your online presence with stunning website design that captivates and engages your audience."
                    ></app-service-box>
                </div>
                <div class="col-lg-3 col-md-6">
                    <app-service-box
                        icon="wrench"
                        title="Maintenance"
                        description="Ensure smooth online operations with hassle-free website maintenance services that keep your website running flawlessly."
                    ></app-service-box>
                </div>
                <div class="col-lg-3 col-md-6">
                    <app-service-box
                        icon="line-chart"
                        title="SEO"
                        description="Drive website success with powerful SEO strategies that increase visibility and attract more customers."
                    ></app-service-box>
                </div>
            </div>
        </div>
    </div>
</body>
